/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import { faChevronRight, faClipboardList, faPhoneAlt, faInfoCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Card, Col, Form, Modal, Row } from "react-bootstrap";
import './Conference.scss'
import { useContext, useEffect, useMemo, useState } from "react";
import CustomControl from "../../components/CustomControl";
import ApiManagerContext from "../../ApiManager.tsx";
import { usdFormatter, validateDecimal, validateDecimalFixed } from "../../tools.js";
import LoadingWrapper from "../../components/LoadingWrapper.jsx";
import AddressControl from "../../components/AddressControl.js";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Wizard from "../../components/Wizard/Wizard.js";
import CustomButton from "../../components/CustomButton.js";
import { toast } from "react-toastify";

const selectItems = ["HR/Legal Services", "Automated Payroll", "HR Software"]
const premiumItems = ["HR/Legal Services", "Automated Payroll", "HR Software", "Fedex Audit Assistance", "Bookkeeping", "Budgeting", "Financial Reporting", "Contract Compliance"]
const legalConsultingItems = ["Access to HR Legal Department", "Access to Contract Compliance Department"]
const features = ["HR/Legal Services", "Automated Payroll", "HR Software", "Fedex Audit Assistance", "Bookkeeping", "Budgeting", "Financial Reporting", "Contract Compliance"]

export default function ConferenceSignUp() {
    const [showPriceCalcModal, setShowPriceCalcModal] = useState(false)
    const [showScheduleModal, setShowScheduleModal] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState(0);
    const [scheduleType, setScheduleType] = useState(null)
    const [pricingDetails, setPricingDetails] = useState({
        grossWeeklyPayroll: 0,
        legalFees: 0,
        payrollCost: 0,
        onlineEmployeeManagement: 0,
        budgeting: 0,
    })

    function hideModals(){
        setShowScheduleModal(false)
        setShowPriceCalcModal(false)
    }

    return (
        <div style={{ minHeight: 'calc(100vh - 127.5px)', display: 'flex', flexDirection: 'column', justifyContent: "center"}}>
            <h3 className="main-header">Plans & Schedule</h3>
            <div className="conference-container">
                <div className="card-wrapper">
                    <div style={{display: 'flex', flexDirection: 'column', gap: 24}}>
                        <div className="option-list">
                            <PlanCard style={{minHeight: 580}} hasShadow buttonLabel="Learn More" plan={"Select"} price={'1.5%'} secondaryPrice={"Gross Payroll"} items={selectItems} onClick={() => {setShowPriceCalcModal(true); setSelectedPlan(0); setScheduleType(null)}}/>
                            <PlanCard style={{minHeight: 580}} hasShadow buttonLabel="Learn More" plan={"Premium"} price={'2.63%'} secondaryPrice={"Gross Payroll"} items={premiumItems} onClick={() => {setShowPriceCalcModal(true); setSelectedPlan(1); setScheduleType(null)}}/>
                            <PlanCard style={{minHeight: 580}} hasShadow buttonLabel="Learn More" plan={"Legal Consulting"} price={'$150'} secondaryPrice={"Per Month"} items={legalConsultingItems} onClick={() => {setShowScheduleModal(true); setSelectedPlan(2); setScheduleType(null)}}/>
                        </div>
                        <div className="option-list">
                            <ConferenceSignUpOptionItem 
                                question="Ready to join?" 
                                statement="Schedule an Onboarding Call" 
                                icon={faPhoneAlt}
                                onClick={() => {setShowScheduleModal(true); setScheduleType("onboarding"); setSelectedPlan(0)}}
                                />
                            <ConferenceSignUpOptionItem 
                                question="Need more information on our features and services?" 
                                statement="Schedule a Call to Learn More" 
                                icon={faInfoCircle}
                                onClick={() => {setShowScheduleModal(true); setScheduleType("information")}}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Modal fullscreen show={showPriceCalcModal} onHide={() => setShowPriceCalcModal(false)}>
                <PriceCalculatorModal selectedPlan={selectedPlan} setShowScheduleModal={setShowScheduleModal} setPricingDetails={setPricingDetails}/>
            </Modal>
            <Modal fullscreen show={showScheduleModal} onHide={hideModals}>
                <ScheduleModal pricingDetails={pricingDetails} scheduleType={scheduleType} selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan}/> 
            </Modal>
        </div>
    );
}

function ConferenceSignUpOptionItem({ statement, question, icon, onClick }) {
    return (
        <div className="option-item shadow" style={{flexDirection: 'column'}}>
            <FontAwesomeIcon icon={icon} className="icon" />
            <div style={{ flex: 1 }}>
                <div className="question">{question}</div>
            </div>
            <Button onClick={onClick} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'white', fontSize: 14, fontWeight: 'bold', gap: 12, padding: 8}}>
                <span>{statement}</span>
                <FontAwesomeIcon style={{ color: 'white' }} icon={faChevronRight} />
            </Button>
        </div>
    );
}

function PriceCalculatorModal({selectedPlan, setShowScheduleModal, setPricingDetails}){
    const [grossWeeklyPayroll, setGrossWeeklyPayroll] = useState("")
    const [legalFees, setLegalFees] = useState("")
    const [payrollCost, setPayrollCost] = useState("")
    const [onlineEmployeeManagement, setOnlineEmployeeManagement] = useState("")
    const [budgeting, setBudgeting] = useState("")
    const [coefficients, setCoeffcients] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const apiManager = useContext(ApiManagerContext);


    const totalPayroll = useMemo(() => {
        return validateDecimalFixed(grossWeeklyPayroll, 2) * 52
    }, [grossWeeklyPayroll])
    const currentCosts = useMemo(() => {
        return  validateDecimalFixed(legalFees) +
                validateDecimalFixed(payrollCost) +
                validateDecimalFixed(onlineEmployeeManagement) +
                validateDecimalFixed(budgeting) +
                validateDecimalFixed(coefficients)
    }, [legalFees, payrollCost, onlineEmployeeManagement, budgeting, coefficients])
    const rate = selectedPlan == 1 ? 0.0263 : 0.015
    const ourFee = useMemo(() => {
        return rate * totalPayroll
    }, [totalPayroll])

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if(grossWeeklyPayroll != ""){
            updateAnnualDerivedValues()
        }else{
            resetDerivedValues()
        }
    }, [grossWeeklyPayroll])

    function loadData(){
        apiManager.createRequest("conferenceShowcase", 'getCoefficients', setIsLoading, (response) => {
            setCoeffcients(response.coefficients)
        }).withNoAlertOnSuccess().send()
    }

    function updateAnnualDerivedValues(){
        const totalContract = totalPayroll*2
        setLegalFees(validateDecimalFixed(totalContract * coefficients.legal, 2))
        setPayrollCost(validateDecimalFixed(totalContract * coefficients.payrollCost, 2))
        setOnlineEmployeeManagement(validateDecimalFixed(totalContract * coefficients.onlineEmployeeManagement, 2))
        setBudgeting(validateDecimalFixed(totalContract * coefficients.budgeting, 2))
    }

    function resetDerivedValues(){
        setLegalFees("")
        setPayrollCost("")
        setOnlineEmployeeManagement("")
        setBudgeting("")
        setPricingDetails({
            grossWeeklyPayroll: 0,
            legalFees: 0,
            payrollCost: 0,
            onlineEmployeeManagement: 0,
            budgeting: 0,
        })
    }

    function setPriceAndOpenSchedule(){
        setPricingDetails({
            grossWeeklyPayroll: grossWeeklyPayroll,
            legalFees: legalFees,
            payrollCost: payrollCost,
            onlineEmployeeManagement: onlineEmployeeManagement,
            budgeting: budgeting,
        })
        setShowScheduleModal(true)
    }
    
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Pricing Calculator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoadingWrapper isLoading={isLoading}>
                     <Row style={{ height: '100%' }}>
                        <Col xl={6} style={{marginBottom: 24 }}>
                            <Card>
                                <Card.Header style={{backgroundColor: 'var(--bs-primary)', color: 'white'}}>
                                    <Card.Title>What You're Paying Now</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <p>Fill out the fields below to calculate your current costs</p>
                                    <CustomControl type="number" max={9999999.99} value={grossWeeklyPayroll} setValue={setGrossWeeklyPayroll} title={"Gross Weekly Payroll"} breakpoints={{ sm: 12 }} />
                                    <hr />
                                    <CustomControl type="number" max={99999999.99} value={legalFees} setValue={setLegalFees} title={"Annual HR/Legal Fees"} breakpoints={{ sm: 12 }} />
                                    <CustomControl type="number" max={99999999.99} value={payrollCost} setValue={setPayrollCost} title={"Annual Payroll Cost"} breakpoints={{ sm: 12 }} />
                                    <CustomControl type="number" max={99999999.99} value={onlineEmployeeManagement} setValue={setOnlineEmployeeManagement}title={"Annual Online Employee Management"} breakpoints={{ sm: 12 }} />
                                    {selectedPlan == 1 && <CustomControl type="number" max={99999999.99}  value={budgeting} setValue={setBudgeting} title={"Annual Budgeting Cost"} breakpoints={{ sm: 12 }} />}
                                    <hr />
                                    <p>Current Cost: {usdFormatter.format(currentCosts)}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={6} style={{ display: 'flex', flexDirection: 'column', marginBottom: 24 }}>
                            <Card style={{ flex: 1, marginBottom: '16px' }}> {/* Add margin for spacing */}
                                <Card.Header style={{backgroundColor: 'var(--bs-primary)', color: 'white'}}>
                                    <Card.Title>What You'll Pay Us</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <p>Our simple fixed rate pricing simplifies expense calculations</p>
                                    <p>Our Fee: {usdFormatter.format(totalPayroll)} x {selectedPlan == 1 ? '2.63%' : '1.5%'}</p>
                                    <hr />
                                    <p>New Cost: {usdFormatter.format(ourFee/52)}/wk</p>
                                </Card.Body>
                            </Card>
                            <Card style={{ flex: 2 }}>
                                <Card.Header style={{backgroundColor: 'var(--bs-primary)', color: 'white'}}>
                                    <Card.Title>How Much You'll Save</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <p>See your potential savings by making the switch</p>
                                    <p>Current Cost: {usdFormatter.format(currentCosts)}</p>
                                    <p>Our Fee: {usdFormatter.format(ourFee)}</p>
                                    <hr />
                                    <p>Total Savings: {usdFormatter.format(currentCosts - ourFee)}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </LoadingWrapper>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={setPriceAndOpenSchedule} style={{color: 'white'}}>Schedule a Call</Button>
            </Modal.Footer>
        </>
    )
}

function ScheduleModal({pricingDetails, scheduleType, selectedPlan, setSelectedPlan}){
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [businessAddress, setBusinessAddress] = useState({})
    const [terminalAddress, setTeriminalAddress] = useState({})
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [stepIndex, setStepIndex] = useState(0);
    const apiManager = useContext(ApiManagerContext)
    const steps = ["Business Details", "Final Steps"]

    const schema = z.object({
        firstName: z.string().min(1, "First Name is required"),
        lastName: z.string().min(1, "Last Name is required"),
        phoneNumber: z.string().length(10, "Phone Number must be 10 digits"),
        email: z.string().email("Invalid email format"),
        companyName: z.string().min(1, "Company Name is required"),
    });

    const form = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            companyName: '',
        },
        mode: 'all'
    })

    function handleSubmit(type){
        const formValues = form.getValues();
        const encodedData = {
            ...pricingDetails,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            phoneNumber: formValues.phoneNumber,
            email: formValues.email,
            companyName: formValues.companyName,
            features: type === 'onboarding' ? [] : selectedFeatures,
            selectedPlan: type === 'information' ? null : selectedPlan,
            terminalThoroughfare: terminalAddress.thoroughfare ?? "",
            terminalPremise: terminalAddress.premise ?? "",
            terminalAdministrativeArea: terminalAddress.administrativeArea ?? "",
            terminalLocality: terminalAddress.locality ?? "",
            terminalPostalCode: terminalAddress.postalCode ?? "",
            businessThoroughfare: businessAddress.thoroughfare ?? "",
            businessPremise: businessAddress.premise ?? "",
            businessAdministrativeArea: businessAddress.administrativeArea ?? "",
            businessLocality: businessAddress.locality ?? "",
            businessPostalCode: businessAddress.postalCode ?? "",
        }

        const name = formValues.firstName + " " + formValues.lastName

        const link = type === 'information'
        ? `https://calendly.com/tacticaltransportationadvisors/information-call?name=${name}&email=${formValues.email}&a1=${formValues.companyName}`
        : `https://calendly.com/tacticaltransportationadvisors/onboarding?name=${name}&email=${formValues.email}&a1=${formValues.companyName}`;

        apiManager?.createRequest("conferenceShowcase", "submit", setIsSubmitting, (_) => {
            window.location.href = link;
        }).withData({data: encodedData}).withNoAlertOnSuccess().send()
    }

    function NextStep(){
        setStepIndex(1)
    }

    function handleToggleFeature(feature){
        setSelectedFeatures((prevState) =>
            prevState.includes(feature) ? prevState.filter(f => f !== feature) : [...prevState, feature]
        );
    }

    const showOnboarding = scheduleType === "onboarding" || scheduleType == null
    const showInformation = scheduleType === "information" || scheduleType == null
    const features = ["HR/Legal Services", "Automated Payroll", "HR Software", "Fedex Audit Assistance", 'Bookkeeping', 'Budgeting', 'Financial Reporting', 'Contract Compliance'];

    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>Scheduling</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{marginLeft: 20, marginRight: 20, marginBottom:12}}>
                    <Wizard steps={steps} stepIndex={stepIndex} setStepIndex={setStepIndex} canContinueAtIndex={() => stepIndex == 1 || (stepIndex == 0 && form.formState?.isValid)}/>
                </div>
                {stepIndex == 0 &&
                <FormProvider>
                    <form onSubmit={form.handleSubmit(NextStep)}>
                        <Row style={{ height: '100%' }}>
                            <Col xl={6} style={{marginBottom: 24 }}>
                                <Card>
                                    <Card.Header style={{backgroundColor: 'var(--bs-primary)', color: 'white'}}>
                                        <Card.Title>Your Details</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <p>Quick and easy - just the essentials to get started</p>
                                        <Controller
                                            name="firstName"
                                            control={form.control}
                                            render={({ field }) => (
                                                <CustomControl title={"First Name*"} max={50} value={field.value} setValue={field.onChange} breakpoints={{ sm: 12 }} validate validationMessage={form.formState?.errors?.["firstName"]?.message}/>
                                            )}
                                        />
                                        <Controller
                                            name="lastName"
                                            control={form.control}
                                            render={({ field }) => (
                                                <CustomControl title={"Last Name*"} max={50} value={field.value} setValue={field.onChange} breakpoints={{ sm: 12 }} validate validationMessage={form.formState?.errors?.["lastName"]?.message}/>
                                            )}
                                        />
                                        <Controller
                                            name="phoneNumber"
                                            control={form.control}
                                            render={({ field }) => (
                                                <CustomControl title={"Phone Number*"} max={10} type="number" clampAsText={true} value={field.value} setValue={field.onChange} breakpoints={{ sm: 12 }} validate validationMessage={form.formState?.errors?.["phoneNumber"]?.message}/>
                                            )}
                                        />
                                        <Controller
                                            name="email"
                                            control={form.control}
                                            render={({ field }) => (
                                                <CustomControl title={"Email*"} max={100} value={field.value} setValue={field.onChange} breakpoints={{ sm: 12 }} validate validationMessage={form.formState?.errors?.["email"]?.message}/>
                                            )}
                                        />
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={6} style={{ display: 'flex', flexDirection: 'column'}}>
                                <Card>
                                    <Card.Header style={{backgroundColor: 'var(--bs-primary)', color: 'white'}}>
                                        <Card.Title>Company Name</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <p>Quick and easy - just the essentials to get started</p>
                                        <Controller
                                            name="companyName"
                                            control={form.control}
                                            render={({ field }) => (
                                                <CustomControl title={"Company Name*"} max={100} value={field.value} setValue={field.onChange} validate validationMessage={form.formState?.errors?.["companyName"]?.message} breakpoints={{ sm: 12 }} />
                                            )}
                                        />
                                        <br/>
                                        <Row style={{gap: 12}}>
                                            <AddressControl title="Business Address (Optional)" address={businessAddress} setAddress={setBusinessAddress} lg={6} xl={4} />
                                            <AddressControl title="Terminal Address (Optional" address={terminalAddress} setAddress={setTeriminalAddress} lg={6} xl={4} />
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                    </Row>
                </form>
                </FormProvider>
            }
            {stepIndex == 1 && 
                 <Row>
                    {showOnboarding &&
                        <Col xl={6} style={{margin: '0 auto', marginBottom: 24}}>
                            <Card>
                                <Card.Header style={{backgroundColor: 'var(--bs-primary)', color: 'white'}}>
                                    <Card.Title>Onboarding</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <p>Continue with your plan to get started with these great features</p>
                                    <ButtonGroup style={{width: '100%'}}>
                                        <Button style={{color: 'white', flex: 1}} variant={selectedPlan === 0 ? 'primary' : 'secondary'} onClick={() => setSelectedPlan(0)}>Select</Button>
                                        <Button style={{color: 'white', flex: 1}} variant={selectedPlan === 1 ? 'primary' : 'secondary'} onClick={() => setSelectedPlan(1)}>Premium</Button>
                                        <Button style={{color: 'white', flex: 1}} variant={selectedPlan === 2 ? 'primary' : 'secondary'} onClick={() => setSelectedPlan(2)}>Legal</Button>
                                    </ButtonGroup>
                                    <div style={{padding: 12}}>
                                        {selectedPlan == 0 && <PlanCard style={{minHeight: 450}} plan={"Select"} price={'1.5%'} secondaryPrice={"Gross Payroll"} items={selectItems}/>}
                                        {selectedPlan == 1 && <PlanCard style={{minHeight: 450}} plan={"Premium"} price={'2.63%'} secondaryPrice={"Gross Payroll"} items={premiumItems}/>}
                                        {selectedPlan == 2 && <PlanCard style={{minHeight: 450}} plan={"Legal Consulting"} price={'$150'} secondaryPrice={"Per Month"} items={legalConsultingItems}/>}
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <CustomButton isLoading={isSubmitting} onClick={() => handleSubmit("onboarding")} style={{color: 'white'}}>Schedule an Onboarding Call</CustomButton>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    {showInformation &&
                        <Col xl={6} style={{margin: '0 auto', marginBottom: 24}}>
                            <Card>
                                <Card.Header style={{backgroundColor: 'var(--bs-primary)', color: 'white'}}>
                                    <Card.Title>Need More Information?</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <p>Select the services that you are intereseted and meet with someone from our team</p>
                                    <Form>
                                        {features.map((feature) => 
                                            <Form.Check 
                                                key={feature}
                                                type="checkbox"
                                                label={feature}
                                                onChange={() => handleToggleFeature(feature)}
                                                style={{ display: 'flex', alignItems: 'center', gap: 12, marginBottom: 8 }}
                                            />
                                        )}
                                    </Form>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <CustomButton isLoading={isSubmitting} onClick={() => handleSubmit("information")} style={{color: 'white', margin: 'auto'}}>Schedule a Call to Learn More</CustomButton>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                </Row>
            }         
            </Modal.Body>
            {stepIndex == 0 && 
                <Modal.Footer>
                    <Button style={{color: 'white'}} type="submit" onClick={form.handleSubmit(NextStep)}>Continue</Button>
                </Modal.Footer>
            }
        </>
    )
}

function PlanCard({plan, price, items, secondaryPrice, onClick, hasShadow, buttonLabel, style}){
    return (
        <div className={`plan-card ${hasShadow ? 'shadow' : ''}`} style={{...style}}>
            <div>
                <h5 style={{textAlign: 'center'}}>{plan}</h5>
                <h3 style={{color: 'var(--bs-primary', textAlign: 'center', margin: '36px 0', fontWeight: 900}}>{price}<span style={{fontSize: 13}}>&nbsp; {secondaryPrice}</span></h3>
                <hr/>
                <ul style={{display: 'flex', flexDirection: 'column', gap: 12, padding: 0}}>
                    {items.map((item, i)=>{
                        return (
                            <li key={i} style={{display: 'flex', gap: 12, justifyContent: 'left', textAlign: 'left', alignItems: 'center'}}>
                                <FontAwesomeIcon color='var(--bs-primary)' icon={faCircleCheck}/>
                                <span>{item}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
            { onClick &&
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 28}}>
                    <Button style={{textAlign: 'center', color: 'white', fontWeight: 'bold', minWidth: 140}} onClick={onClick}>{buttonLabel}</Button>
                </div>
            }
        </div>
    )
}
  