/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './App.css';
import React from 'react';
import NavigationBar from './navigation/NavigationBar/NavigationBar';
import Router from './Router';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import ApiManagerContext, { useApiManager } from './ApiManager.tsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  const [isLoading, failedRequest, apiManager] = useApiManager();
  return (
    <ApiManagerContext.Provider value={apiManager}>
        <BrowserRouter>
            <NavigationBar/>
            <div style={{flex: 1, top:65, position: 'relative'}}>
                <Router/>
                <Footer/>
            </div>
        </BrowserRouter>
        <ToastContainer toastStyle={{fontSize: 14}} position='bottom-right' autoClose={3000}/>
    </ApiManagerContext.Provider>
  )
}

