/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';

export default function CustomButton({style, isLoading, onClick, disabled, icon, children}) {
    return (
        <Button disabled={disabled || isLoading} onClick={onClick} style={{...style}}>
            { isLoading ? <Spinner size='sm'/> : 
                <div style={{display: 'flex', gap: 8, alignItems: 'center', justifyContent: 'center'}}>
                    {children}
                    { icon &&
                        <FontAwesomeIcon icon={icon}/>
                    }
                </div>
            }
        </Button>
    );
    
}