/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import Cookies from "universal-cookie";
import { createContext, useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';


const DEV_MODE = false;
const API_URL = DEV_MODE ? "http://192.168.1.52/tc/public/" : "https://api.tacticalcontractor.com";
const API_VERSION_CODE = "1.9.4";
const AUTH_PASSWORD = "25f5053ceaf48be2f0cbafc927ecc73654201a83"

const ApiManagerContext = createContext<ApiManager | undefined>(undefined);
export default ApiManagerContext;

type useApiManagerReturnType = [
  failedRequest: ApiRequest | undefined, 
  isLoading: boolean, 
  apiManager: ApiManager,
]

export function useApiManager(): useApiManagerReturnType {
  const [failedRequest, setFailedRequest] = useState<ApiRequest | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const apiManager = useRef(new ApiManager(setFailedRequest, setIsLoading));


  return [failedRequest, isLoading, apiManager.current];
}

class ApiManager {
  setFailedRequest: (failedRequest: ApiRequest | undefined) => void;
  setIsLoading: (isLoading: boolean) => void; 

  constructor(
    setFailedRequest: (failedRequest: ApiRequest | undefined) => void,
    setIsLoading: (isLoading: boolean) => void) {
    this.setFailedRequest = setFailedRequest;
    this.setIsLoading = setIsLoading;  
  }

  createRequest(type: string, target: string, setIsLoading: (value: boolean) => void, onSuccess?: ((response: any, status: number) => void) | undefined): ApiRequest {
    const packagedData: object = {
      type: type,
      target: target,
      token: new Cookies().get('tcToken'),
      version: API_VERSION_CODE,
      password: AUTH_PASSWORD,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    return new ApiRequest(this, packagedData, setIsLoading, onSuccess);
  }
}

class ApiRequest {
  apiManager: ApiManager
  data: any;
  setIsLoading: (value: boolean) => void;
  onSuccess: ((response: any, status: number) => void) | undefined;
  onFail: ((response: any, status: number) => void) | undefined;

  returnStatuses: number[] = [200];
  noAlertOnStatuses: number[] = [];
  alertOnSuccess: boolean = true;

  abortController: AbortController | null = null;
  timeout: NodeJS.Timeout | null = null;
  cancelled: boolean = false;

  constructor(
    apiManager: ApiManager,
    data: any,
    setIsLoading: (value: boolean) => void,
    onSuccess: ((response: any, status: number) => void) | undefined

  ) {
    this.apiManager = apiManager;
    this.data = data;
    this.setIsLoading = setIsLoading;
    this.onSuccess = onSuccess;
  }

  withData(data: any): ApiRequest {
    this.data = { ...this.data, ...data };
    return this;
  }

  withUid(uid: number): ApiRequest {
    this.data.uid = uid;
    return this;
  }

  withReturnStatuses(returnStatuses: [number]): ApiRequest {
    this.returnStatuses = returnStatuses;
    return this;
  }

  withNoAlertOnStatuses(statuses: [number]): ApiRequest {
    this.noAlertOnStatuses = statuses;
    return this;
  }

  withNoAlertOnSuccess(): ApiRequest {
    this.alertOnSuccess = false;
    return this;
  }

  withOnFail(onFail: (response: any, status: number) => void) {
    this.onFail = onFail;
    return this;
  }

  send() {
    this.execute();
  }

  resend() {
    this.execute();
  }

  cancel() {
    this.cancelled = true;
    this.abortController?.abort();
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  private async execute() {
    this.setIsLoading(true);
    this.abortController = new AbortController();
    this.timeout = setTimeout(() => this.abortController!.abort(), 8000);
    const auth = new Cookies().get("tcAuth")

    if (DEV_MODE) {
      console.log("->", this.data);
    }

    const payload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.data),
    };

    try {
      const fetchResponse = await fetch(API_URL, {
        ...payload,
        signal: this.abortController.signal,
      });
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      const responseObject = await fetchResponse.json();

      if (DEV_MODE) {
        console.log("<-", responseObject);
      }

      if (this.returnStatuses.includes(fetchResponse.status)) {
        if (this.alertOnSuccess) {
          if (!this.noAlertOnStatuses.includes(fetchResponse.status)) {
            toast.success(responseObject.message ?? "Success")
          }
        }
        this.onSuccess?.(responseObject, fetchResponse.status);
      } else if (fetchResponse.status == 401) {
        // this.apiManager.setAuth(undefined);
      } else {
        if (this.onFail) {
          this.onFail(responseObject, fetchResponse.status);
        }
        if (!this.noAlertOnStatuses.includes(fetchResponse.status)) {
          toast.error(responseObject.message ?? "Something went wrong");
        }
      }
    } catch (error) {
      if (this.abortController.signal.aborted) {
        if (this.cancelled) {
          console.log("Request cancelled");
        } else {
          console.log("Request timed out");
          this.apiManager.setFailedRequest(this);
        }
      } else {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
    this.setIsLoading(false);
  }
}
