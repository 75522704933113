/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function LoadingWrapper({isLoading, children, color = 'var(--bs-primary)', width = '100%', height = '100%'}) {    
    if (isLoading) {
        return (
            <div style={{width: width, height: height, padding: 4}}>
                <div style={{aspectRatio: 1, maxWidth: '100%', maxHeight: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto'}}>
                    <Spinner style={{height: '80%', width: '80%', maxHeight: 150, maxWidth: 150, color: color}}/>
                </div>
            </div>
        )
    } else {
        return (
            <>
                {children}
            </>
        );
    }
}