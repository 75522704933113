/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { validateDecimal, validateInteger } from '../tools';
import OptionalCol from './OptionalCol';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from 'react-bootstrap';

export default function CustomControl({type = 'text', title, originalValue, value, setValue, onChange, min, max, clampAsText, breakpoints, validate, validationMessage, showMaxLabel = false, ...props}) {

    const handleOnChange = (event) => {
        let newValue = event.target.value;

        if (max !== undefined && (type === 'text' || !!clampAsText) && newValue.length > max) {
            newValue = newValue.slice(0, max);
        } else if (max !== undefined && type === 'number' && !clampAsText && validateDecimal(newValue) > max) {
            newValue = max.toString();
        } else if (min !== undefined && type === 'number' && validateDecimal(event.target.value) < min) {
            newValue = min.toString();
        }
        setValue(newValue);
    }

    const isInvalid = validate ? validationMessage != undefined : undefined;
    const style = originalValue !== undefined && originalValue !== null && value && value.toString() !== originalValue.toString() ? {backgroundColor: '#faffe3'} : {};

    const control = (
        <Form.Control isInvalid={isInvalid} placeholder=' ' value={value ?? ''} type={type} onWheel={(e) => e.target.blur()} onChange={handleOnChange} style={style} {...props}/>
    )

    const content = (
        <FloatingLabel label={title} style={{position: 'relative', flex: 1}}>
            <OverlayTrigger overlay={
                <Popover style={{ display: validationMessage ? 'block' : 'none'}}>
                    <Popover.Body>{validationMessage}</Popover.Body>
                </Popover>
            }>
                {control}
            </OverlayTrigger>
            {/* {validationMessage && <Form.Control.Feedback type='invalid'>{validationMessage}</Form.Control.Feedback>} */}
            { showMaxLabel &&
                <div style={{fontSize: 11, position: 'absolute', right: 8, top: 40}}>
                    { max && 
                        <>
                            { (type == 'text' || !!clampAsText) ? 
                                <div>
                                    <span>{value?.length ?? 0}</span>
                                    <span>/</span>
                                    <span>{max}</span>
                                </div>
                            : type == 'number' ?
                            <span style={{color: validateDecimal(value) == max ? 'red' : 'black'}}>{`max: ${max}`}</span>
                            :
                                ''
                            }
                        </>
                    }
                </div>
            }
        </FloatingLabel>
    )
    
    return (
        <OptionalCol breakpoints={breakpoints}>
            {content}
        </OptionalCol>
    )
}