/*
__/\\\\\\\\\\\\\\\__/\\\\\\\\\\\\\\\_____/\\\\\\\\\____        
 _\///////\\\/////__\///////\\\/////____/\\\\\\\\\\\\\__       
  _______\/\\\_____________\/\\\________/\\\/////////\\\_      
   _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_     
    _______\/\\\_____________\/\\\_______\/\\\\\\\\\\\\\\\_    
     _______\/\\\_____________\/\\\_______\/\\\/////////\\\_   
      _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_  
       _______\/\\\_____________\/\\\_______\/\\\_______\/\\\_ 
        _______\///______________\///________\///________\///__
            
            COPYRIGHT TACTICAL TRANSPORTATION ADVISORS, INC. 
            ALL RIGHTS RESERVED.
*/

import './Wizard.css';
import React, {useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEllipsisH, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Popover } from 'react-bootstrap';

function Wizard({steps, completedSteps, stepIndex, setStepIndex, canContinueAtIndex}) {

    const [isPopover, setIsPopover] = useState(true);
    const ref = useRef();

    useEffect(()=>{
        function handleIsPopover(){
            ref.current?.clientWidth < steps?.length * 140 ? setIsPopover(true) : setIsPopover(false);
        }
        handleIsPopover()
        
        window.addEventListener('resize', handleIsPopover);

        return ()=>{
            window.removeEventListener('resize', handleIsPopover);
        }
    }, [])

    const handleStepChange = (index) => {
        if (setStepIndex) {
            setStepIndex(index);
        }
    }

    const elements = steps.map((step, index) => {
        let elementsToReturn = [];
        elementsToReturn.push(
            <div key={step} className={setStepIndex ? 'cursor-pointer' : ''} onClick={() => {if(canContinueAtIndex()){handleStepChange(index)}}}>
                <div className={'wizard-icon-wrapper' + (index < stepIndex ? ' wizard-icon-wrapper-complete' : index === stepIndex ? ' wizard-icon-wrapper-pending' : '')}>
                    {!isPopover ?
                        <>
                            {index <= stepIndex ? <FontAwesomeIcon className={'wizard-icon' + (index < stepIndex ? ' wizard-icon-complete' : '')} icon={index < stepIndex ? faCheck : faEllipsisH}/> : ''}
                            <p style={{position: 'absolute', whiteSpace: 'nowrap', bottom: -28, margin: 0, fontWeight: index === stepIndex ? 'bold' : 'normal', opacity: index === stepIndex ? 1.0 : 0.7}}>
                                {index >= stepIndex || !completedSteps ? step : completedSteps[index]}
                            </p> 
                        </>
                    :
                        <OverlayTrigger placement={'top'} overlay={
                            <Popover>
                                <Popover.Body>
                                        <span style={{whiteSpace: 'pre-line'}}>{step}</span>
                                </Popover.Body>
                            </Popover>
                        }>
                         {index <= stepIndex ? <FontAwesomeIcon className={'wizard-icon' + (index < stepIndex ? ' wizard-icon-complete' : '')} icon={index < stepIndex ? faCheck : faEllipsisH}/> :  <></>}
                    </OverlayTrigger>
                    }
                </div>
            </div>
        );
        
        if (index < steps.length - 1) {
            elementsToReturn.push(<div key={step + ' line'} style={{display: 'flex', flex: 1, height: 2, marginTop: 20, backgroundColor: 'var(--bs-primary)', opacity: index >= stepIndex ? 0.0 : 1.0}}/>);
        }
        return elementsToReturn;
    })

    return (
        <div ref={ref} style={{position: 'relative', display: 'flex', padding: '8px 40px 8px 40px'}} >
            {elements}
        </div>
    )
}

export default Wizard;
